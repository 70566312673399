import { Injectable } from '@angular/core';
import { storage } from '@core/utils';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  darkMode$ = new BehaviorSubject<boolean>(
    !!storage.getItem('appThemeDark')
  );

  get isDarkMode(): boolean {
    return this.darkMode$.getValue();
  }

  toggleDarkTheme(){
    storage.setItem('appThemeDark', !this.isDarkMode);
    this.darkMode$.next(!this.isDarkMode);
  }

  constructor() { }
}
