import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import {Login} from '@core/models/auth/login';
import {ExistCheckResponse} from '@core/models/common/exist-check-response';
import {UserRegisterRequest} from '@core/models/user/user-register-request';
import {UserResponse} from '@core/models/user/user-response';
import {storage} from '@core/utils/storage/storage.utils';
import {environment} from '@env/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import {CommonResponse} from '@core/models/common/common-response';
import {UserDetail} from '@core/models/user/user-detail';
import * as CryptoJS from 'crypto-js';
import {QrCodeResponse} from '@core/models/auth/qr-code-response';
import {ConfigDetail} from '@core/models/user/config-detail';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    http = inject(HttpClient);

    isAuthenticated$ = new BehaviorSubject<boolean>(!!storage.getItem('appSession'));
    isLogoutState$ = new BehaviorSubject<boolean>(false);
    kickAccount = new BehaviorSubject<boolean>(false);

    isSkipped$ = new BehaviorSubject<boolean>(!!storage.getItem('appSession')?.isSkipped);

    canProceed$ = new BehaviorSubject<boolean>(!!storage.getItem('canProceed'));

    kickTheAccount() {
        this.kickAccount.next(true);
    }

    get isAuthenticated(): boolean {
        return this.isAuthenticated$.getValue();
    }

    get canProceed(): boolean {
        return this.canProceed$.getValue();
    }

    get isSkipped(): boolean {
        return this.isSkipped$.getValue();
    }

    appSession$ = new BehaviorSubject(storage.getItem('appSession'));
    configSession$ = new BehaviorSubject(storage.getItem('configSession'));

    private registerUrl: string;
    private loginUrl: string;
    private authUrl: string;
    private qrCodeUrl: string;

    constructor() {
        this.authUrl = environment.apiUrl + '/auth';
        this.registerUrl = this.authUrl + '/register';
        this.loginUrl = this.authUrl + '/login';
        this.qrCodeUrl = this.authUrl + '/qr-code';
    }

    login(loginData: Login): Observable<UserResponse> {
        const reqBody: Login = loginData;

        return this.http.post<UserResponse>(this.loginUrl, reqBody);
    }

    get userDetail(): UserDetail {
        return this.appSession$.value!.userDetail;
    }

    get userId(): number {
        return this.appSession$.value!.user;
    }

    get token(): string {
        return this.appSession$.value!.token;
    }

    updateUserBalance(coin: number) {
        this.appSession$.value!.userDetail.balance = coin;
        storage.setItem('appSession', this.appSession$.value!);
    }

    logout() {
        storage.clear();
        //storage.removeItem('appSession');
        //storage.removeItem('configSession');
        this.isLogoutState$.next(true);
        this.isAuthenticated$.next(false);
    }

    register(registerData: UserRegisterRequest): Observable<UserResponse> {
        const reqBody = registerData;

        return this.http.post<UserResponse>(this.registerUrl, reqBody);
    }

    updateSession(userDetail: UserDetail) {
        this.appSession$.value!.userDetail = userDetail;
        storage.setItem('appSession', this.appSession$.value!);
        this.appSession$.next(this.appSession$.value);
    }

    setSession(uuid: string, isSkipped: boolean, userResponse: UserResponse) {
        storage.setItem('appSession', {
            user: userResponse.user.id,
            token: userResponse.token,
            refreshToken: userResponse.refreshToken,
            isSkipped: isSkipped,
            uuid: uuid,
            socketToken: userResponse.user.socketToken,
            userDetail: userResponse.user,
        });
        this.isSkipped$.next(isSkipped);
        this.appSession$.next({
            user: userResponse.user.id,
            token: userResponse.token,
            refreshToken: userResponse.refreshToken,
            isSkipped: isSkipped,
            uuid: uuid,
            socketToken: userResponse.user.socketToken,
            userDetail: userResponse.user,
        });
        this.isAuthenticated$.next(true);
    }

    updateUserDetailSession(userData: UserDetail) {
        this.appSession$.value!.userDetail = userData;
    }

    setConfigSession(configDetail: ConfigDetail) {
        storage.setItem('configSession', configDetail);
        this.configSession$.next(configDetail);
    }
    //Register Steps
    checkEmail(email: string) {
        const reqParams = {
            email: email,
        };

        const options = {params: reqParams};
        return this.http.get<ExistCheckResponse>(this.authUrl + '/checkEmail', options);
    }

    public refreshToken(refreshToken: string): Observable<UserResponse> {
        const reqBody = {};

        let reqHeaders = new HttpHeaders();
        reqHeaders = reqHeaders.append('Authorization', `Bearer ${refreshToken}`);
        const options = {headers: reqHeaders};
        return this.http.post<UserResponse>(this.authUrl + '/refreshToken', reqBody, options);
    }

    async generateFingerprint() {
        const fpPromise = FingerprintJS.load();

        const fp = await fpPromise;
        const result = await fp.get();

        return result;
    }

    forgotPassword(email: string): Observable<CommonResponse> {
        const reqBody = {
            email: email,
        };

        return this.http.post<UserResponse>(this.authUrl + '/forgotPassword', reqBody);
    }

    generateQrCode(socketId: string, uuid: string): Observable<QrCodeResponse> {
        const reqParams = {
            socketId: socketId,
        };

        const options = {params: reqParams, headers: {uuid: uuid}};
        return this.http.get<QrCodeResponse>(this.qrCodeUrl, options);
    }

    cryptCommon(data: string) {
        const crypted = CryptoJS.AES.encrypt(data, environment.appKey).toString();
        return crypted.replaceAll('+', 'xMl3Jk').replaceAll('/', 'Por21Ld').replaceAll('=', 'Ml32');
    }

    decryptCommon(data: string) {
        let decyrpy = data.replaceAll('xMl3Jk', '+').replaceAll('Por21Ld', '/').replaceAll('Ml32', '=');
        decyrpy = CryptoJS.AES.decrypt(decyrpy, environment.appKey).toString(CryptoJS.enc.Utf8);
        return decyrpy;
    }

    setCanProceed(canProceed: boolean) {
        storage.setItem('canProceed', canProceed);
        this.canProceed$.next(canProceed);
    }

    setShowInLeaderboard(showInLeaderboard: boolean) {
        this.userDetail.isShowInLeaderboard = showInLeaderboard;
        this.updateUserDetailSession(this.userDetail);
        this.updateSession(this.userDetail);
    }
}
